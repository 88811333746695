<template>
  <div>
    <h4 class="text-center m-0">{{ $t('purchasedTabs.sender_name_title') }}</h4>
    <div class="font-weight-bold mt-3 text-center paymentDetail-param_plan paymentDetail-param_plan_main">
      {{ plan.localized_price }}
    </div>
    <div class="font-weight-bold mb-4 text-center paymentDetail-monthly_plan paymentDetail-param_plan_main">
        {{$t('forms.buyPackages.monthly')}}
    </div>
    <div class="text-center mt-2 mb-3">
      <div>
        {{ $t('purchasedTabs.sender_name_fee') }} {{ plan.localized_fee }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button @click.prevent="submit" class="btn btn-primary btn-lg shadow-2">{{ $t('forms.buyPackages.continue') }}</button>
    </div>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'BuySenderNameForm',
  props: {
    plan: {
      type: Object,
    },
  },
  emits: ['select-package'],
  setup(props, { emit }) {
    return {
      submit: async() => {
        emit('select-package', props.plan)
      },
    }
  },
}
</script>
